// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useEffect } from 'react';
// import logo from './logo.svg';
import styles from './App.module.css';
// to import paths.
import RoutePaths from './RoutePaths';
// pls look into doc: https://v5.reactrouter.com/web/api/Hooks/useparams
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Welcome from './components/Welcome/Welcome';
// to import Login pager
import Login from './components/Login/Login';
import TopBar from './components/TopBar/TopBar';

import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Dashboard from './components/Dashboard/Dashboard';
import LogSucHandler from './components/Login/LogSucHandler';

import MsgModal from './common/ui/MsgModal';
import StudySetDetail from './components/MyHomework/StudySet/StudySetDetail';
import FullscreenFlashCard from './components/MyHomework/Flashcard/FullscreenFlashcard';
import ComplexExerciseUI from './components/MyHomework/Exam/ComplexExerciseUI';
import ComplexLearningUI from './components/MyHomework/Learn/ComplexLearningUI';
import NotFound from './components/Notfound/NotFound';

import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import StudySetEdit from './components/MyHomework/StudySet/StudySetEdit';
import TrashMain from './components/Trash/TashMain';
import ComplexListeningUI from './components/MyHomework/Listen/ComplexListeningUI';
import {
  isChineseExistedInBrowser,
  getUserSetLanguage,
  getLanByUrl,
} from './common/utils/LanguageHelper';

import { getMeta4Url, getMetaByLan } from './common/metas/MetasHelper';
import MainLibSelector from './components/Import/LibsImport/MainLibSelector';

// due to <App> is only one in memory, so we can use a global variable to store the onClose function.
let onCloseFunc = undefined;
function App() {
  const { t } = useTranslation();
  const url = window.location.href;
  // add "if" to avoid to get meta tags again.
  if (!window.metas) window.metas = getMeta4Url(url);

  // const [count, setCount] = useState(0);
  const [modalTitle, setModalTitle] = React.useState('');
  const [modalMsg, setModalMsg] = React.useState('');
  const [btnVariant, setBtnVariant] = React.useState('primary');

  /**
   * @param {func} onCloseFuncParam - a function to be called when the modal is closed.
   */
  window.showModal = function (title, msg, btnVariant, onCloseFuncParam) {
    setModalTitle(title);
    setModalMsg(msg);
    setBtnVariant(btnVariant || 'primary');
    onCloseFuncParam && (onCloseFunc = onCloseFuncParam);
  };
  window.showErrorModal = function (msg, onCloseFuncParam) {
    setModalTitle(t('错误'));
    setModalMsg(msg);
    setBtnVariant('danger');
    onCloseFuncParam && (onCloseFunc = onCloseFuncParam);
  };
  window.showSucModal = function (msg, onCloseFuncParam) {
    setModalTitle(t('成功'));
    setModalMsg(msg);
    setBtnVariant('success');
    onCloseFuncParam && (onCloseFunc = onCloseFuncParam);
  };
  window.showSuccessModal = window.showSucModal;

  useEffect(() => {
    if (!window.language) {
      // to detect language by url.
      // priority: url > user_set_language > browser_language > default_language
      let lan = getLanByUrl(url);
      if (!lan && getUserSetLanguage()) lan = getUserSetLanguage();
      if (!lan) lan = isChineseExistedInBrowser() ? 'zh' : 'en';
      window.language = lan;
      i18n.changeLanguage(window.language);
    }

    // console.log('window.language: ', window.language);
    window.metas = getMetaByLan(window.language);

    // to delete existed meta tags.
    // const existingMetaTags = document.querySelectorAll(
    //   'meta[name="keywords"], meta[name="description"]'
    // );
    // // if cost of existed those tags is 4. remove first 2 of them.
    // if (existingMetaTags.length > 2) {
    //   // to remote first and  second tags
    //   let removal = 0;
    //   existingMetaTags.forEach((tag) => {
    //     if (removal > 1) return;
    //     document.head.removeChild(tag);
    //     removal++;
    //   });
    // }
  }, []);

  return (
    <ThemeProvider breakpoints={['lg', 'md', 'sm', 'xs', 'xxs']}>
      <Router forceRefresh={true}>
        <div className={styles.App} id="app_container">
          <header className={styles['App-header']}>
            {/*<img src={logo} className={styles['App-logo']} alt="logo" />*/}

            {/* Testing env variable - https://vitejs.dev/guide/env-and-mode.html#env-files */}
            {/*<p>SVG path: {`${import.meta.env.VITE_SVG_PATH}`}</p>*/}

            <TopBar />
          </header>
          {/* add a space below the topbar */}

          <div className="spacer" />
          <Switch>
            <Route path="/about">
              <main>About</main>
            </Route>

            <Route path={RoutePaths.HOME} exact>
              <Welcome />
            </Route>

            <Route path="/home" component={Welcome} />
            <Route path={RoutePaths.LAN_EN_HOME} component={Welcome} />

            <Route path="/login" component={Login} />

            <Route path={RoutePaths.PROFILE} component={LogSucHandler} />
            <Route path={RoutePaths.DASHBOARD_SUBPAGE} component={Dashboard} />

            <Route path={RoutePaths.LIST_DETAIL} component={StudySetDetail} />

            <Route path={RoutePaths.LIST_EDIT} component={StudySetEdit} />

            <Route
              path={RoutePaths.FLASHCARD_PAGER}
              component={FullscreenFlashCard}
            />

            <Route
              path={RoutePaths.LEARN_PAGER}
              component={ComplexLearningUI}
            />
            <Route path={RoutePaths.LIB_IMPORT} component={MainLibSelector} />

            <Route path={RoutePaths.EXAM_PAGER} component={ComplexExerciseUI} />
            <Route
              path={RoutePaths.LISTENING_PAGER}
              component={ComplexListeningUI}
            />
            <Route path={RoutePaths.TRASH} component={TrashMain} />

            {/* 404路由规则 */}
            <Route path="*" component={NotFound} />
          </Switch>
          {modalTitle ? (
            <MsgModal
              title={modalTitle}
              msg={modalMsg}
              btnVariant={btnVariant}
              onClose={() => {
                setModalTitle('');
                onCloseFunc && onCloseFunc();
              }}
            />
          ) : null}
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
